<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column prop="pic" label="图标" width="100" show-overflow-tooltip>
        <template v-slot="s">
          <img :src="s.row.pic | tomedia" style="height: 2rem;width: 2rem;border-radius: .5rem">
        </template>
      </el-table-column>
      <el-table-column prop="title" label="名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="tel" label="电话" show-overflow-tooltip></el-table-column>
      <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
      <el-table-column label="坐标" :formatter="e=>`${e.lat},${e.long}`" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" fixed="right" width="300">
        <template slot="header">
          <el-button @click="editBox=true">添加核销门店</el-button>
        </template>
        <template v-slot="s">
          <el-button @click="$router.push({
          name:'shopPluginScanGoodsClerk',
          query:{
            store_id:s.row.id,
          }
          })" type="primary">核销员管理</el-button>
          <el-button @click="edit(s.row)" type="primary">编辑</el-button>
          <el-button @click="del(s.row)" type="danger">删除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog title="编辑门店" :visible.sync="editBox" width="60rem" @close="editClose">
      <div class="flex-def flex-zCenter flex-zBetween">
        <el-form :model="editForm" label-width="4rem" style="width: 25rem">
          <el-form-item label="图标">
            <y_upload_img v-model="editForm.pic"></y_upload_img>
          </el-form-item>
          <el-form-item label="名称">
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
          <el-form-item label="地址">
            <el-input v-model="editForm.address"></el-input>
          </el-form-item>
          <el-form-item label="经纬度">
            <el-row :gutter="20">
              <el-col :span="10"><el-input v-model="editForm.lat"></el-input></el-col>
              <el-col :span="1">，</el-col>
              <el-col :span="10"><el-input v-model="editForm.long"></el-input></el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="editForm.tel"></el-input>
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="editSubmit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
        <div style="width: 30rem">
          <add @point="point" :lat="editForm.lat" :long="editForm.long"></add>
        </div>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>

import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import add from "@/components/qqmap/add";
export default {
  components:{y_upload_img,add},
  name: "manage",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      editBox:false,
      editForm:{
        pic:"",
        title:"",
        tel:"",
        address:"",
        long:"",
        lat:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    del({id}){
      this.$u.api.shop.plugin.scanGoods.storeDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    point(lat,lng){
      this.editForm.lat = lat + "";
      this.editForm.long = lng + "";
    },
    editSubmit(){
      this.$u.api.shop.plugin.scanGoods.storeEdit(this.editForm).then(()=>{
        this.$message.success("操作成功");
        this.editBox = false;
        this.load();
      })
    },
    edit(item){
      this.editForm = {...item};
      this.editBox = true;
    },
    editClose(){
      this.editForm = {
        pic:"",
        title:"",
        tel:"",
        address:"",
        long:"",
        lat:"",
      }
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.shop.plugin.scanGoods.storeAll().then(res=>{
        this.list = res;
        this.total = res.length;
      })
    },
  },
}
</script>

<style scoped>

</style>